import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    navbar: {
        backgroundColor: '#5C5C5C',
    },
    heading: {
        color: 'white',
        fontFamily: 'Times New Roman',
        paddingBottom:'13px'
    }
}));
const NavBar = () => {
    const classes = useStyles();
     return (
         <div>
             <AppBar className={classes.navbar} position="static">
                 <Toolbar>
                     <Typography variant="h2" className={classes.heading}>Speak Up</Typography>
                </Toolbar>
             </AppBar>
        </div>
    );
}
export default NavBar;
