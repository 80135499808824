import NavBar from './components/NavBar';
import Home from './components/Home'
import RenderForm from "./components/RenderForm";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { templates } from "./templates.js";
import Submitted from './components/Submitted'
import "./App.css";
const App = () => {
    return (
        <div className="page-content">
            <NavBar />
            <Router>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/Submitted" element={<Submitted />} />
                    {
                        templates.map((r, index) => {
                            return (
                              <Route key={index} exact path={`/${r.name}`} element={<RenderForm country={r.name} />} />
                            );
                        })
                    }
                </Routes>
            </Router>
        </div>
    );
}
export default App;
    